<!--
 * @Author: 侯怀烨
 * @Date: 2020-11-25 19:56:44
 * @LastEditTime: 2021-02-22 11:22:38
 * @LastEditors: Please set LastEditors
 * @Description: 入库
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\lalal\index.vue
-->
<template>
  <!-- NAME[epic=库管] 入库查询 -->
  <div style="padding: 20px">
    <el-row>
      <el-col :span="22">
        <el-form ref="ruleForm" inline :model="form">
          <el-form-item prop="in_id">
            <el-select
              v-model="form.in_id"
              placeholder="请选择入库仓库"
              style="width: 160px"
              clearable
            >
              <el-option
                v-for="(item, index) in warehouse"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="add_id">
            <el-select
              v-model="form.add_id"
              placeholder="申请人"
              style="width: 120px"
              clearable
            >
              <el-option
                v-for="(item, index) in personnel"
                :key="index"
                :label="item.user_name"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="inout_type">
            <el-select
              v-model="form.inout_type"
              placeholder="入库类型"
              style="width: 120px"
              clearable
            >
              <el-option
                v-for="(item, index) in Typesof"
                :key="index"
                :label="item.type_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item prop="status">
            <el-select
              v-model="form.status"
              placeholder="状态"
              clearable
              style="width: 120px"
            >
              <el-option
                v-for="(item, index) in status"
                :key="index"
                :label="item.status_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item prop="value1">
            <el-date-picker
              v-model="value1"
              style="width: 290px"
              type="datetimerange"
              start-placeholder="起始时间"
              end-placeholder="结束时间"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="keyword">
            <el-input
              v-model="form.keyword"
              style="width: 180px"
              placeholder="商品名称，简拼"
              suffix-icon="el-icon-search"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              v-model="form.remark"
              style="width: 120px"
              placeholder="备注"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="handlerReset">重 置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="handlePrint">打 印</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleExport">导 出</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click="handlerMore">更多查询条件</el-button>
          </el-form-item>
          <el-form-item v-show="More" prop="out_id">
            <el-select
              v-model="form.out_id"
              placeholder="请选择出库仓库"
              style="width: 160px"
              clearable
            >
              <el-option
                v-for="(item, index) in warehouse"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="More" prop="keyword_cust">
            <el-input
              v-model="form.keyword_cust"
              style="width: 180px"
              placeholder="请输入 客户名称，简拼"
              suffix-icon="el-icon-search"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span></span>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <el-form inline>
          <!-- <el-form-item>
            <el-button type="primary" @click="handlerAdd">添加入库</el-button>
          </el-form-item> -->
          <!-- <el-form-item>
            <el-button>导入</el-button>
          </el-form-item>
          <el-form-item>
            <el-button>导出</el-button>
          </el-form-item> -->
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        show-summary
        :summary-method="summaryFunction"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div
              v-if="list.label == '单号'"
              class="underline"
              @click="handleOrderDetail(row)"
            >
              {{ row[list.prop] }}
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" width="200" fixed="right">
          <template #default="{ row }">
            <el-button
              v-show="row.bill_status != 2"
              type="text"
              @click="handlershenhe(row)"
            >
              审核
            </el-button>
            <el-button type="text" @click="handlerredo(row)">作废</el-button>
            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-button type="text">打印</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-warehousing ref="add" @handlershuxin="handlershuxin"></add-warehousing>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="RKCX"
      :new-obj="form"
      :type="1"
    ></ruilang-display-dialog>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
    <advanceOrder ref="advanceOrder"></advanceOrder>
    <diaobodanOrder ref="diaobodanOrder"></diaobodanOrder>
    <rukuOrder ref="rukuOrder"></rukuOrder>
    <chukuOrder ref="chukuOrder"></chukuOrder>
    <baosunOrder ref="baosunOrder"></baosunOrder>
    <baoyiOrder ref="baoyiOrder"></baoyiOrder>
  </div>
</template>

<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import AddWarehousing from './components/AddWarehousing.vue'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { day_n } from '@/utils/Time'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import diaobodanOrder from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'
  import rukuOrder from '@/views/project/treasury/requisition/inquire/components/AddWarehousing.vue'
  import chukuOrder from '@/views/project/treasury/requisition/Outstock/components/AddWarehousing.vue'
  import baosunOrder from '@/views/project/treasury/requisition/Lossreport/components/goodslink.vue'
  import baoyiOrder from '@/views/project/treasury/requisition/baoYiDan/component/create.vue'
  export default {
    components: {
      AddWarehousing,
      RuilangDisplayDialog,
      saleOrderDetail,
      advanceOrder,
      diaobodanOrder,
      rukuOrder,
      chukuOrder,
      baosunOrder,
      baoyiOrder,
    },
    data() {
      return {
        value1: [],
        form: {
          inout_class: 2,
          in_id: '',
          out_id: '',
          inout_type: '',
          status: '',
          add_id: '',
          keyword: '',
          keyword_cust: '',
          start_time: '',
          end_time: '',
          remark: '',
          pageNo: 1,
          pageSize: 10,
        },
        tableData: [],
        total: 0,
        More: false,
        status: [],
        warehouse: [],
        personnel: [],
        Typesof: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          personnel: '/baseAdmin/common/user-list',
          Typesof: '/baseAdmin/common/bill-in-type',
          status: '/baseAdmin/common/allot-status',
          list: '/depotAdmin/in-out/list',
        },
        colemd: [
          {
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '180',
          },
          {
            label: '状态',
            align: 'center',
            prop: 'bill_status_text',
            width: '120',
          },
          {
            label: '入库仓库',
            align: 'center',
            prop: 'in_depot',
            width: '120',
          },
          {
            label: '入库类型',
            align: 'center',
            prop: 'bill_type_text',
            width: '120',
          },
          {
            label: '出货仓库/客户',
            align: 'center',
            prop: 'out_depot',
            width: '120',
          },
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '150',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'goods_num',
            width: '100',
          },
          {
            label: '价格',
            align: 'center',
            prop: 'goods_price',
            width: '100',
          },
          {
            label: '金额',
            align: 'center',
            prop: 'total_price',
            width: '100',
          },
          // {
          //   label: '状态',
          //   align: 'center',
          //   prop: 'bill_status_text',
          //   width: '80',
          // },
          {
            label: '申请人',
            align: 'center',
            prop: 'add_name',
            width: '100',
          },
          {
            label: '申请时间',
            align: 'center',
            prop: 'add_time',
            width: '150',
          },
          {
            label: '审核人',
            align: 'center',
            prop: 'check_name',
            width: '100',
          },
          {
            label: '审核时间',
            align: 'center',
            prop: 'check_time',
            width: '150',
          },
          {
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '200',
          },
        ],
      }
    },
    watch: {
      value1(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initTime()
    },
    mounted() {
      this.handlerwarehouse()
      this.handlerpersonnel()
      this.handlerTypesof()
      this.handlerstatus()
      this.handlerlist()
    },

    methods: {
      initTime() {
        this.form.start_time = day_n(1)[1] + ' 00:00:00'
        this.form.end_time = day_n(1)[1] + ' 23:59:59'
        this.value1 = [day_n(1)[1] + ' 00:00:00', day_n(1)[1] + ' 23:59:59']
      },
      handlershuxin() {
        this.handlerInquire()
      },
      handlerlink(row) {
        this.$refs.add.type(1)
        this.$refs.add.handlercreateData(row)
        this.$refs.add.showAdd = true
      },
      handlershenhe(row) {
        console.log(row, '')
        this.$refs.add.type(1)
        this.$refs.add.handlercreateData(row)
        this.$refs.add.showAdd = true
      },
      handlerredo(row) {
        this.$refs.add.type(1)
        this.$refs.add.handlercreateData(row)
        this.$refs.add.showAdd = true
      },
      handlerAdd() {
        this.$refs.add.showAdd = true
        this.$refs.add.type(0)
      },
      handlerReset() {
        this.$refs['ruleForm'].resetFields()
        this.handlerlist()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res, '')
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerMore() {
        this.More = !this.More
      },
      handlerTiem(val) {
        console.log(val, '')
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
          console.log(this.form.start_time, this.form.end_time, '')
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      handlerstatus() {
        postAction(this.url.status, {})
          .then((res) => {
            console.log(res, '状态')
            this.status = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerTypesof() {
        postAction(this.url.Typesof, {})
          .then((res) => {
            console.log(res, '入库类型')
            this.Typesof = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerpersonnel() {
        postAction(this.url.personnel, {})
          .then((res) => {
            console.log(res, '人员')
            this.personnel = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [9]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleExport() {
        downloadFile(
          '/depotAdmin/in-out/list-export',
          '入库查询.xlsx',
          this.form
        )
      },
      handleOrderDetail(row) {
        console.log(row)
        switch (row.bill_type) {
          case 'XS':
            this.$refs['saleOrderDetail'].isshowDialog = true
            this.$refs['saleOrderDetail'].id = row.url_id
            break
          case 'DB':
            row.id = row.bill_id
            this.$refs.diaobodanOrder.dialogVisible = true
            this.$refs.diaobodanOrder.type = 1
            this.$refs.diaobodanOrder.handlerlist(row)
            break
          case 'RK':
            row.url_id = row.bill_id
            this.$refs.rukuOrder.type(1)
            this.$refs.rukuOrder.handlercreateData(row)
            this.$refs.rukuOrder.showAdd = true
            break
          case 'CK':
            row.url_id = row.bill_id
            this.$refs.chukuOrder.type(1)
            this.$refs.chukuOrder.handlercreateData(row) //url_id
            this.$refs.chukuOrder.showAdd = true
            break
          case 'BS':
            row.id = row.bill_id
            this.$refs.baosunOrder.showlink = true
            this.$refs.baosunOrder.isEdit = true
            this.$refs.baosunOrder.handlerinfo(row) //id
            break
          case 'BY':
            this.$refs.baoyiOrder.showDialog = true
            this.$refs.baoyiOrder.id = row.bill_id
            // type1添加 2查看 3审核
            this.$refs.baoyiOrder.type = 2
            this.$refs.baoyiOrder.title = '报溢单详情'
            break
        }
      },
    },
  }
</script>

<style></style>
